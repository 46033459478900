<template>
	<span class="google-books-logo">
		<svg
			width="146px"
			height="27px"
			viewBox="0 0 146 27"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
		>
			<g transform="translate(0.959956, 0.687500)">
				<g
					opacity="0.54"
					transform="translate(83.719689, 2.062500)"
					fill="#000000"
					fill-rule="nonzero"
					class="google-books-logo__text"
				>
					<path d="M0.037374861,17.25 L0.037374861,0.075 L6.24160178,0.075 C7.58709677,0.075 8.74571746,0.525 9.71746385,1.40625 C10.6892102,2.2875 11.1937709,3.4125 11.1937709,4.7625 C11.1937709,5.5875 10.9882091,6.3 10.5583982,6.91875 C10.1285873,7.5375 9.5679644,8.00625 8.87652948,8.34375 L8.87652948,8.4375 C9.71746385,8.71875 10.4088988,9.225 10.9508343,9.91875 C11.4927697,10.63125 11.7730812,11.4375 11.7730812,12.375 C11.7730812,13.78125 11.2498331,14.94375 10.2220245,15.88125 C9.1942158,16.8 7.97953281,17.26875 6.57797553,17.26875 L0.037374861,17.26875 L0.037374861,17.25 Z M2.24249166,7.425 L6.24160178,7.425 C7.06384872,7.425 7.73659622,7.14375 8.24115684,6.6 C8.74571746,6.05625 8.98865406,5.4375 8.98865406,4.78125 C8.98865406,4.125 8.74571746,3.525 8.25984427,2.98125 C7.77397108,2.4375 7.13859844,2.15625 6.33503893,2.15625 L2.24249166,2.15625 L2.24249166,7.425 Z M2.24249166,15.13125 L6.67141268,15.13125 C7.51234705,15.13125 8.20378198,14.83125 8.72703003,14.25 C9.25027809,13.65 9.51190211,13.0125 9.51190211,12.3 C9.51190211,11.5875 9.23159066,10.95 8.68965517,10.36875 C8.14771969,9.7875 7.43759733,9.4875 6.5592881,9.4875 L2.24249166,9.4875 L2.24249166,15.13125 Z" />
					<path d="M14.7817575,6.9 C15.9216908,5.7 17.3606229,5.11875 19.1172414,5.11875 C20.8738598,5.11875 22.312792,5.71875 23.4527253,6.9 C24.5926585,8.1 25.1532814,9.58125 25.1532814,11.38125 C25.1532814,13.18125 24.5926585,14.6625 23.4527253,15.8625 C22.312792,17.0625 20.8738598,17.64375 19.1172414,17.64375 C17.3606229,17.64375 15.9216908,17.04375 14.7817575,15.8625 C13.6418242,14.6625 13.0812013,13.18125 13.0812013,11.38125 C13.0812013,9.58125 13.6418242,8.08125 14.7817575,6.9 Z M16.407564,14.45625 C17.1737486,15.225 18.0707453,15.61875 19.0985539,15.61875 C20.1263626,15.61875 21.0233593,15.225 21.7895439,14.45625 C22.5557286,13.6875 22.9294772,12.65625 22.9294772,11.38125 C22.9294772,10.10625 22.5557286,9.075 21.7895439,8.30625 C21.0233593,7.5375 20.1263626,7.14375 19.0985539,7.14375 C18.0707453,7.14375 17.1737486,7.5375 16.407564,8.30625 C15.6413793,9.075 15.2676307,10.10625 15.2676307,11.38125 C15.2863181,12.65625 15.6600667,13.6875 16.407564,14.45625 Z" />
					<path d="M28.0498331,6.9 C29.1897664,5.7 30.6286986,5.11875 32.385317,5.11875 C34.1419355,5.11875 35.5808676,5.71875 36.7208009,6.9 C37.8607341,8.1 38.4213571,9.58125 38.4213571,11.38125 C38.4213571,13.18125 37.8607341,14.6625 36.7208009,15.8625 C35.5808676,17.0625 34.1419355,17.64375 32.385317,17.64375 C30.6286986,17.64375 29.1897664,17.04375 28.0498331,15.8625 C26.9098999,14.6625 26.349277,13.18125 26.349277,11.38125 C26.349277,9.58125 26.9098999,8.08125 28.0498331,6.9 Z M29.694327,14.45625 C30.4605117,15.225 31.3575083,15.61875 32.385317,15.61875 C33.4131257,15.61875 34.3101224,15.225 35.076307,14.45625 C35.8424917,13.6875 36.2162403,12.65625 36.2162403,11.38125 C36.2162403,10.10625 35.8424917,9.075 35.076307,8.30625 C34.3101224,7.5375 33.4131257,7.14375 32.385317,7.14375 C31.3575083,7.14375 30.4605117,7.5375 29.694327,8.30625 C28.9281424,9.075 28.5543938,10.10625 28.5543938,11.38125 C28.5543938,12.65625 28.9281424,13.6875 29.694327,14.45625 Z" />
					<polygon points="42.3830923 0.075 42.3830923 10.65 47.447386 5.49375 50.269188 5.49375 50.269188 5.5875 45.7094549 10.1625 50.4934372 17.1375 50.4934372 17.23125 47.839822 17.23125 44.1583982 11.68125 42.3830923 13.4625 42.3830923 17.25 40.1779755 17.25 40.1779755 0.075" />
					<path d="M55.7259177,17.625 C54.4177976,17.625 53.3339266,17.30625 52.4929922,16.66875 C51.6333704,16.03125 51.0166852,15.225 50.6242492,14.26875 L52.5864294,13.44375 C53.2031146,14.925 54.2682981,15.65625 55.7632925,15.65625 C56.4547275,15.65625 57.0153504,15.50625 57.4451613,15.20625 C57.8749722,14.90625 58.0992214,14.49375 58.0992214,14.00625 C58.0992214,13.2375 57.5572859,12.7125 56.4921023,12.45 L54.1187987,11.86875 C53.3713014,11.68125 52.6611791,11.30625 51.9884316,10.78125 C51.3156841,10.2375 50.9793103,9.525 50.9793103,8.60625 C50.9793103,7.575 51.4464961,6.73125 52.3621802,6.075 C53.2778643,5.41875 54.3617353,5.1 55.6324805,5.1 C56.6602892,5.1 57.5946607,5.34375 58.3982202,5.8125 C59.2204672,6.28125 59.7997775,6.95625 60.1361513,7.8375 L58.2300334,8.625 C57.8002225,7.59375 56.9032258,7.06875 55.5577308,7.06875 C54.9036707,7.06875 54.3617353,7.2 53.9132369,7.48125 C53.4647386,7.7625 53.2404894,8.11875 53.2404894,8.5875 C53.2404894,9.2625 53.7637375,9.7125 54.7915462,9.95625 L57.1087875,10.5 C58.2113459,10.7625 59.0149055,11.19375 59.5568409,11.8125 C60.080089,12.43125 60.341713,13.14375 60.341713,13.93125 C60.341713,14.98125 59.9119021,15.8625 59.0522803,16.575 C58.1926585,17.2875 57.0714127,17.625 55.7259177,17.625 Z" />
				</g>
				<g class="google-books-logo__brand">
					<path d="M0.130812013,9.8625 C0.130812013,4.44375 4.67185762,0.01875 10.0912125,0.01875 C13.0812013,0.01875 15.2115684,1.2 16.8186874,2.7375 L14.931257,4.63125 C13.7913237,3.54375 12.2215795,2.71875 10.0912125,2.71875 C6.14816463,2.71875 3.0647386,5.90625 3.0647386,9.8625 C3.0647386,13.81875 6.14816463,17.00625 10.0912125,17.00625 C12.6513904,17.00625 14.10901,15.975 15.0433815,15.0375 C15.8095662,14.26875 16.3141268,13.1625 16.5010011,11.6625 L10.0912125,11.6625 L10.0912125,8.98125 L19.1172414,8.98125 C19.2106785,9.46875 19.2667408,10.03125 19.2667408,10.66875 C19.2667408,12.69375 18.7248053,15.1875 16.9494994,16.95 C15.2302558,18.75 13.025139,19.70625 10.1098999,19.70625 C4.67185762,19.6875 0.130812013,15.28125 0.130812013,9.8625 Z" fill="#4285F4" fill-rule="nonzero" />
					<path d="M26.5361513,7.03125 C23.0416018,7.03125 20.2011123,9.69375 20.2011123,13.36875 C20.2011123,17.025 23.0416018,19.70625 26.5361513,19.70625 C30.0307008,19.70625 32.8711902,17.025 32.8711902,13.36875 C32.8711902,9.69375 30.0307008,7.03125 26.5361513,7.03125 Z M26.5361513,17.19375 C24.6300334,17.19375 22.9668521,15.61875 22.9668521,13.35 C22.9668521,11.0625 24.6113459,9.50625 26.5361513,9.50625 C28.4422692,9.50625 30.1054505,11.0625 30.1054505,13.35 C30.1054505,15.61875 28.4422692,17.19375 26.5361513,17.19375 Z" fill="#EA4335" fill-rule="nonzero" />
					<path d="M40.3648498,7.03125 C36.8703003,7.03125 34.0298109,9.69375 34.0298109,13.36875 C34.0298109,17.025 36.8703003,19.70625 40.3648498,19.70625 C43.8593993,19.70625 46.6998888,17.025 46.6998888,13.36875 C46.6998888,9.69375 43.8593993,7.03125 40.3648498,7.03125 Z M40.3648498,17.19375 C38.4587319,17.19375 36.7955506,15.61875 36.7955506,13.35 C36.7955506,11.0625 38.4400445,9.50625 40.3648498,9.50625 C42.2896552,9.50625 43.9341491,11.0625 43.9341491,13.35 C43.9341491,15.61875 42.2709677,17.19375 40.3648498,17.19375 Z" fill="#FBBC05" fill-rule="nonzero" />
					<path d="M57.3330367,7.40625 L57.3330367,8.4375 L57.2395996,8.4375 C56.6229143,7.6875 55.4269188,7.0125 53.9132369,7.0125 C50.7550612,7.0125 47.8585095,9.7875 47.8585095,13.36875 C47.8585095,16.9125 50.7550612,19.6875 53.9132369,19.6875 C55.4269188,19.6875 56.6229143,19.0125 57.2395996,18.24375 L57.3330367,18.24375 L57.3330367,19.1625 C57.3330367,21.58125 56.043604,22.875 53.9692992,22.875 C52.268743,22.875 51.2222469,21.65625 50.792436,20.625 L48.3817575,21.6375 C49.0731924,23.325 50.9232481,25.3875 53.9879867,25.3875 C57.2395996,25.3875 59.9866518,23.475 59.9866518,18.7875 L59.9866518,7.40625 L57.3330367,7.40625 Z M54.1561735,17.19375 C52.2500556,17.19375 50.6429366,15.58125 50.6429366,13.36875 C50.6429366,11.1375 52.2500556,9.50625 54.1561735,9.50625 C56.043604,9.50625 57.519911,11.1375 57.519911,13.36875 C57.5385984,15.6 56.043604,17.19375 54.1561735,17.19375 Z" fill="#4285F4" fill-rule="nonzero" />
					<polygon fill="#34A853" fill-rule="nonzero" points="61.8927697 0.69375 64.6585095 0.69375 64.6585095 19.3125 61.8927697 19.3125" />
					<path d="M72.2642937,17.19375 C70.8440489,17.19375 69.8536151,16.5375 69.1995551,15.28125 L77.6462736,11.775 L77.3659622,11.0625 C76.8427141,9.6375 75.2355951,7.03125 71.9652948,7.03125 C68.7136819,7.03125 66.0040044,9.6 66.0040044,13.36875 C66.0040044,16.9125 68.676307,19.70625 72.2642937,19.70625 C75.1608454,19.70625 76.8240267,17.925 77.5341491,16.89375 L75.3850945,15.45 C74.6562848,16.5 73.6845384,17.19375 72.2642937,17.19375 Z M72.0587319,9.46875 C73.1612903,9.46875 74.0956618,10.0125 74.3946607,10.81875 L68.7510567,13.1625 C68.676307,10.725 70.6384872,9.46875 72.0587319,9.46875 Z" fill="#EA4335" fill-rule="nonzero" />
				</g>
			</g>
		</svg>
	</span>
</template>
